<template>
  <div class="invoice-body" id="invoice-body">
    <div v-show="show" id="invoice">
      <div :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': 'rtl', 'text-align': 'right' }">
        <div v-if="rel_logo" style="width: 100%; text-align: center;padding-bottom: 10px;padding-top: 15px;"><img :src="rel_logo" alt="" style="width: 80px;"></div>
<!--        <h5 class="text-center">فاتورة ارجاع</h5>-->
<!--        <h5 class="text-center">Refund Invoice</h5>-->
        <h5 class="text-center">{{data ? data.invoice_title_ar: ''}}</h5>
        <h5 class="text-center">{{data ? data.invoice_title_en : '' }}</h5>
        <h4 v-if="inContentTemp('invoice_code')" class="text-center font-weight-800" style="margin-top: 15px">
          رقم الفاتورة: {{ data.invoice_code }}
        </h4>

        <h3 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.company && data.company.business_name && inContentTemp('business_name_ar')">{{ data.company.business_name }}</h3>
        <template v-if="data.branch">
          <h5 class="text-center" style="margin-bottom: 5px;margin-top: 5px;" v-if="data.branch.name">{{ data.branch.name }}</h5>
          <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">
            <span v-if="data.branch.country">{{ data.branch.country ? data.branch.country : ''}}</span>
            <span v-if="data.branch.city">{{ data.branch.city ? ' - ' + data.branch.city : '' }}</span>
            <span v-if="data.branch.address_1">{{data.branch.address_1 ? ' - ' + data.branch.address_1 : ''}}</span>
          </p>
        </template>
        <!--        <p class="text-center" style="margin-bottom: 5px;margin-top: 5px;">-->
<!--          <span v-if="inContentTemp('company_city_name')">{{ data.company ? (data.company.city_name ? ' - ' + data.company.city_name : '') : '' }}</span>-->
<!--          &lt;!&ndash;        <span v-if="inContentTemp('company_state')">{{ data.company ? (data.company.state ? ' - ' + data.company.state : '') : '' }}</span>&ndash;&gt;-->
<!--          <span v-if="inContentTemp('company_address_1')">{{ data.company ? (data.company.address_1 ? ' - ' + data.company.address_1 : '') : '' }}</span>-->
<!--          <span v-if="inContentTemp('company_postal_code')">{{ data.company ? (data.company.postal_code ? ' - ' + data.company.postal_code : '') : '' }}</span>-->
<!--        </p>-->

        <div v-if="data.pos_store && is_show_contact && (mobileFullData(data.pos_store.mobile) || mobileFullData(data.pos_store.mobile2))" style="width: 100%;display: block;background-color: #cccccc;">
          <h5 style="margin-bottom: 0;text-align: center;padding-top: 5px;padding-bottom: 5px;">
            <span>التواصل: </span>
            <span v-if="mobileFullData(data.pos_store.mobile)" style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile) }}</span>
            <template v-if="mobileFullData(data.pos_store.mobile2)">
              <span style="direction: ltr;display: inline-block;margin-right: 5px;margin-left: 5px;" v-if="mobileFullData(data.pos_store.mobile2) "> - </span>
              <span style="direction: ltr;display: inline-block;"> {{ mobileFullData(data.pos_store.mobile2) }}</span>
            </template>
          </h5>
        </div>
        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 5px 10px;">
          <p style="margin-bottom: 0;margin-top: 0;" v-if="inContentTemp('invoice_date')"><span><b> تاريخ الفاتورة: </b> <span> {{ data.create_date_time }} </span></span></p>
          <p style="margin-bottom: 0;margin-top: 10px;">
            <span v-if="inContentTemp('employee_user_name') && data.pos_session && data.pos_session.user_name">
              <b> أمين الصندوق: </b>
              <span> {{ data.pos_session.user_name }} </span>
            </span>
          </p>
        </div>

        <div v-if="is_show_customer_details" style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;">
          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer && data.customer.name">
            <span v-if="inContentTemp('fullname')" style="width: 100%;"><span><b> العميل: </b> </span><span> {{ data.customer.name }} </span></span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile) && data.customer.mobile" style="margin-right: 10px;margin-left: 10px;"> | </span>
            <span v-if="inContentTemp('customer_mobile') && mobileFullData(data.customer.mobile) && data.customer.mobile" style="direction: ltr;display: inline-block;width: 100%;"> {{ mobileFullData(data.customer.mobile) }} </span>
          </p>
          <!--        <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')">-->
          <!--          <span><b> الرقم الضريبي: </b></span>-->
          <!--          <span>{{ data.customer.tax_register }}</span>-->
          <!--        </p>-->
          <p style="margin-bottom: 0;width: 100%;" v-if="data.customer && data.customer.commercial_register && inContentTemp('commercial_register')">
            <span><b> السجل التجاري: </b></span>
            <span>{{ data.customer.commercial_register }}</span>
          </p>

          <p style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;" v-if="data.customer">
            <span v-if="inContentTemp('customer_code')" style="width: 100%;"><span><b> رقم العميل: </b> </span><span v-if="data.customer.customer_code">{{ data.customer.customer_code }}#</span></span>
            <span v-if="(inContentTemp('customer_debit') && data.customer_balance && data.customer_balance.debit) || (inContentTemp('customer_credit') && data.customer_balance && data.customer_balance.credit )" style="margin-right: 10px;margin-left: 10px;">|</span>
            <span style="width: 100%;" v-if="data.customer_balance && data.customer_balance.credit && inContentTemp('customer_credit')">
              <span><b> الرصيد: </b> </span><span>{{ data.customer_balance.credit }}</span>
            </span>
            <span v-else-if="data.customer_balance && data.customer_balance.debit && inContentTemp('customer_debit')">
              <span style="width: 100%;"><b> الرصيد المستحق: </b> </span><span>{{ data.customer_balance.debit }}</span>
            </span>
          </p>
          <p style="margin-bottom: 0;" v-if="data.customer && data.customer.tax_register && inContentTemp('tax_register')"><span><b> الرقم الضريبي: </b> </span><span> {{ data.customer.tax_register }} </span></p>
        </div>

        <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>

        <div v-if="products_template_design == 1" style="margin-top: 15px;">
          <div style="border-right: 1px solid #000000;margin-top: 15px;padding: 0px 10px;" v-for="(row, index) in items_list" :key="index">
            <div style="margin-bottom: 0;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;">
                <span v-if="index == 0" style="display: block;"><b>المنتج</b></span>
                <span style="display: block;">{{ row.item ? row.item.name : '' }}</span>
              </p>
              <p style="margin: 0;">
                <span v-if="index == 0" style="display: block;"><b>العدد</b></span>
                <span style="display: block;">{{ row.qty }}</span>
              </p>
              <p style="margin: 0;">
                <span v-if="index == 0" style="display: block;"><b>السعر </b></span>
                <span style="display: block;">{{ row.unit_price }}</span>
              </p>
            </div>
            <div style="margin-bottom: 0;margin-top:10px;display: flex;justify-content: space-between;width: 100%;">
              <p style="margin: 0;display: contents;">
                <span><b>المجموع</b></span>
                <span> {{  row.subtotal_before_discount}}</span>
              </p>
              <p style="margin: 0;display: contents;">
                <span><b>الخصم</b></span>
                <span> {{ row.discount_calc ? row.discount_calc : '0' }}</span>
              </p>
              <p style="margin: 0;display: contents;">
                <span><b>الإجمالي</b></span>
                <span> {{ row.subtotal_after_discount ? row.subtotal_after_discount : '0' }}</span>
              </p>
            </div>

            <p><span style="border-bottom: 2px dotted #000;width: 70%;height: 0;display: block;margin: 0 auto;"></span></p>
          </div>
        </div>
        <div v-else-if="products_template_design == 2" style="margin-top: 15px;">
          <table class="table" style="margin-top: 15px">
            <thead>
            <tr>
              <th>المنتج</th>
              <th class="text-center">الكمية</th>
              <th class="text-center">السعر</th>
              <th class="text-center">الخصم</th>
              <!--            <th class="text-center">{{ $t('invoice_sales_print.VAT') }}</th>-->
              <th class="text-center">الإجمالي</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in items_list" :key="index">
              <td style="overflow: hidden;"><span
                  style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;">{{ row.item ? row.item.name : '' }}</span>
              </td>
              <td class="text-center">{{ row.qty }}</td>
              <td class="text-center">{{ row.unit_price }}</td>
              <td class="text-center">{{ row.discount_calc ? row.discount_calc : '0' }}</td>
              <!--            <td class="text-center">{{ row.amount_tax ? row.amount_tax : '0' }}</td>-->
              <td class="text-center">{{ row.subtotal_after_discount ? row.subtotal_after_discount : '0' }}</td>
              <!--          <td>{{ row.subtotal_before_tax }}</td>-->
            </tr>
            </tbody>
          </table>
        </div>

        <div style="margin-top: 15px;">
          <div style="min-width: 264px;padding-left: 5px;">
            <div class="total-border d-flex justify-content-between special-border mt-1">
              <div><h5 class="mb-0">المجموع</h5></div>
              <div><span><b>{{ data.invoice_total_before_taxes ? data.invoice_total_before_taxes : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>
            <div class="total-border d-flex justify-content-between special-border mt-1">
              <div><h5 class="mb-0">مجموع الخصومات</h5></div>
              <div><span class="sum-sum"><b>{{ data.invoice_total_discount ? data.invoice_total_discount : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>

            <!--          <div class="total-border d-flex justify-content-between special-border mt-1">-->
            <!--            <div><h5 class="mb-0">ضريبة القيمة المضافة )15%(</h5></div>-->
            <!--            <div><span class="sum-sum"><b>{{ data.total_taxes ? data.total_taxes : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>-->
            <!--          </div>-->

            <div class="total-border d-flex justify-content-between special-border mt-1">
              <div><h5 class="mb-0">الإجمالي</h5></div>
              <div><span><b>{{ data.invoice_refund_total ? data.invoice_refund_total : '0' }}</b></span> <span>{{ data.currency_code }}</span></div>
            </div>

          </div>

          <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
          <div class="text-center " style="margin: 5px auto;width: 190px;height: 203px;margin-top: 15px;">
            <div v-show="data.data_qr_code" style="padding: 5px;width: 190px;height: 203px;">
              <qr-code :text="data.data_qr_code ? data.data_qr_code : '1'" :size="size" :error-level="'L'"></qr-code>
            </div>
          </div>

          <div v-if="data.pos_policy && is_show_terms_and_conditions">
            <p v-if="is_break_before_footer"><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>
            <h4 class="text-center">الشروط والأحكام</h4>
            <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;" v-html="data.pos_policy"></div>

          </div>
          <!--          <div v-if="data.usage_policy && is_show_terms_and_conditions">-->
          <!--            <p><span style="border: 1px solid #000;width: 70%;height: 0px;display: block;margin: 0 auto;"></span></p>-->
          <!--            <p>{{ data.usage_policy }}</p>-->
          <!--          </div>-->


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VueQRCodeComponent from 'vue-qrcode-component';
import i18nService from "@/core/services/i18n.service";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "point-sale-invoice",
  components: {
    'qr-code': VueQRCodeComponent
  },
  data() {
    return {
      fetched: false,
      lang: this.$i18n.locale,
      mainRoute: 'v3/sales/refund_invoices',
      data: {},
      items_list: [],
      size: 175,
      calc_total: 0,
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      templateMainRoute: 'base/print_setting/type',
      template_for: 'PosSales',
      template: null,
      content_temp: [],
      is_break_before_footer: false,
      is_payment_details: false,
      is_empty_row: false,
      is_show_contact: false,
      is_show_customer_details: false,
      is_show_terms_and_conditions: false,
      products_template_design: 1,
      print_size: '80',
      is_company_have_tax: false,
    }
  },
  computed: {
    rel_logo: function () {
      if (this.data && this.data.company && this.data.company.logo_url) {
        return this.data.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
  },
  methods: {
    async getData() {
      let _id = this.id;

      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {


        let _inner_data = response.data.data;
        this.items_list = response.data.data.items_list;


        // let _taxes = (response.data.data.total_taxes ? (parseFloat(response.data.data.total_taxes) / 100) : 0);
        // let _subtotal = (response.data.data.invoice_refund_subtotal ? parseFloat(response.data.data.invoice_refund_subtotal) : 0);
        // let _shipping = (response.data.data.total_shipping ? parseFloat(response.data.data.total_shipping) : 0);
        // let _discount = (response.data.data.total_refund_discount ? parseFloat(response.data.data.total_refund_discount) : 0);
        // let _total_taxes = _subtotal * _taxes;
        // Object.assign(_inner_data, {total_taxes: _total_taxes});
        // Object.assign(_inner_data, {invoice_refund_subtotal: _total_taxes + _subtotal});
        this.data = _inner_data;
        this.calc_total = response.data.data.invoice_refund_total;

        this.show = true;

      });
    },
    showPaymentDetails(payment_amount_details){
      if (payment_amount_details.length > 0){
        let status = false;
        payment_amount_details.forEach((row)=>{
          if (row.amount != 0 && row.amount != null){
            status = true;
          }
        });
        return status;
      }else {
        return false;
      }
    },
    printData() {
      this.printContent();
    },
    printContent() {

      if (document.getElementById('invoice-body')) {
        const content = document.getElementById('invoice-body').innerHTML;

        const printWindow = window.open('', '_self');

        printWindow.document.write('<html><head><title>Print</title>');

        printWindow.document.write('<style>');
        printWindow.document.write(`
              @media print {
                @page {
                  size: auto;
                  margin: 2mm;
                }
              }
              .store-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 99px;
                margin: 13px;
              }
              #invoice {
                font-family: "NotoSansArabic";
                width: ${this.print_size}mm;
                position: relative;
              }
              h5{
                margin:0;
              }
              html, body {
                    margin: 0.39mm 0.27mm;
                    background: #EEF0F8;
              }

              table, thead, tr, th, tbody, td {

                font-size: 14px;
                background-color: transparent !important;
              }

              table{
                border: 1px solid #000000;
                border-radius: 6px;
                border-color: #000000 !important;
                width: 100%;
              }

              .text-center {
                text-align: center !important;
              }

              th, p, h5 {

                font-size: 14px !important;

              }

              .special-border {
                padding: 5px 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
              }
              .table td {
                border-left: 1px solid #000000 !important;
                border-top: 0;
              }
              .table thead th {
                border-left: 1px solid #000000;
                border-bottom: 1px solid #000000;
              }
              .table thead tr {
                border: 0 !important;
              }
              .table thead th:last-child {
                border-left: 0 !important;
              }
              .table td:last-child {
                border-left: 0 !important;
              }
              .table th, .table td {
                padding: 5px !important;
              }
.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
          `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');


        setTimeout(() => {
          // printWindow.print();
          // printWindow.close();
          this.completedForPrint();
        }, 300);
      }
    },
    // printData() {
    //   // let tableId = 'invoice'
    //   // let divToPrint = document.getElementById(tableId);
    //   // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divToPrint.outerHTML);
    //   // i18nService.getActiveLanguage();
    //   // setTimeout(() => {
    //   //   window.print();
    //   //   window.close();
    //   // }, 100)
    //
    //   // let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
    //   // let title = "invoice";
    //   // mywindow.document.write(`<html><head><title>${title}</title>`);
    //   // mywindow.document.write(`<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>`);
    //   // mywindow.document.write('</head><body >');
    //   // mywindow.document.write(document.getElementById('invoice').innerHTML);
    //   // mywindow.document.write('</body></html>');
    //   // setTimeout(() => {
    //   //   mywindow.document.close(); // necessary for IE >= 10
    //   //   mywindow.focus(); // necessary for IE >= 10*/
    //   //
    //   //   mywindow.print();
    //   //   mywindow.close();
    //   // }, 100)
    //   // return true;
    //   let _id = 'invoice';
    //   this.exportPDF(_id);
    // },
    // exportPDF(_id) {
    //   let that = this;
    //   let element = document.getElementById(_id);
    //   let width = element.clientWidth - 205;
    //   let height = element.clientHeight;
    //
    //   let pdf = jsPdfExport("p", "px", [width, height]);
    //
    //   pdf.html(document.getElementById(_id), {
    //     html2canvas: {
    //       scale: 0.5,
    //     },
    //     callback: function (pdf) {
    //       // if (that.type == 'pdf') {
    //       //   pdf.save('invoice');
    //       // } else {
    //       pdf.autoPrint();
    //       pdf.output('dataurlnewwindow');
    //       // }
    //
    //       // var iframe = document.createElement('iframe');
    //       // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
    //       // document.body.appendChild(iframe);
    //       // iframe.src = pdf.output('datauristring');
    //     }
    //   });
    // },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          // window.close();
        }
      }

    },

    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_show_contact = response.data.data.is_show_contact ? response.data.data.is_show_contact : false;
        this.print_size = response.data.data.print_size ? response.data.data.print_size : '80';
        this.is_show_customer_details = response.data.data.is_show_customer_details ? response.data.data.is_show_customer_details : false;
        this.is_show_terms_and_conditions = response.data.data.is_show_terms_and_conditions ? response.data.data.is_show_terms_and_conditions : false;
        this.products_template_design = response.data.data.products_template_design ? response.data.data.products_template_design : 1;


      });
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },
    mobileFullData(mobile) {
      if (mobile) {
        if (mobile.length > 5) {
          return mobile;
        }
      }
      return null;
    },
  },
  mounted() {
    let promise = this.getData();
    let promise2 = this.printSetting();
    this.printSetting();
    Promise.all([promise, promise2]).then(() => {
      this.printData();
    });
  }
}
</script>

<style scoped>
@media print {
  @page {
    size: auto;
    margin: 0;
  }

  .store-logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 99px;
    margin: 13px;
  }

  .print-action-holder, #kt_aside, #kt_header, #kt_subheader, #kt_footer, #kt_header_mobile {
    display: none !important;
  }

  .print-action-holder {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .print-action-holder__btn-print {
    background: #0c6f58;
    color: white;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 20px;
  }

  #invoice {
    font-family: "NotoSansArabic";
    width: 525px;
    position: relative;
    margin: 0 auto;
    border: 1px solid;
  }


  html, body {
    width: 525px;
    margin: 0 auto;
  }

  table, thead, tr, th, tbody, td {
    font-size: 20px;
    background-color: transparent !important;
  }

  table {
    border: 1px solid #000000;
    border-radius: 6px;
    border-color: #000000 !important;
  }

  .text-center {
    text-align: center !important;
  }

  th, p, h5, span {
    font-size: 20px !important;
  }

  .special-border {
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 5px 10px;
  }

  .table td {
    border-left: 1px solid #000000 !important;
    border-top: 0;
  }

  .table thead th {
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .table thead tr {
    border: 0 !important;
  }

  .table thead th:last-child {
    border-left: 0 !important;
  }

  .table th, .table td {
    padding: 5px !important;
  }

  .table-p td {
    border-left: 1px solid #000000 !important;
    border-top: 0 !important;
  }

  .table-p thead th {
    border-left: 1px solid #000000 !important;
    border-bottom: 1px solid #000000 !important;
  }

  .table-p thead tr {
    border: 0 !important;
  }

  .table-p thead th:last-child {
    border-left: 0 !important;
  }

  .table-p td:last-child {
    border-left: 0 !important;
  }

  .table-p th, .table-p td {
    padding: 5px !important;
  }
}

.table-p td {
  border-left: 1px solid #000000 !important;
  border-top: 0 !important;
}

.table-p thead th {
  border-left: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.table-p thead tr {
  border: 0 !important;
}

.table-p thead th:last-child {
  border-left: 0 !important;
}

.table-p td:last-child {
  border-left: 0 !important;
}

.table-p th, .table-p td {
  padding: 5px !important;
}
</style>